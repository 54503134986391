import { Dialog, Drawer, IconButton } from '@material-ui/core'
import { Help } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { path } from 'ramda'
import { makeStyles } from '@material-ui/core/styles'
import SideDrawerListActions from '../list/SideDrawerListActions'
import { useState } from 'react'
import CreateListForm from '../list/CreateListForm'

const useStyles = makeStyles({
  sideDrawerContainer: {
    width: '400px',
    display: 'flex',
    flexDirection: 'column',

    '& .primaryListContainer': {
      display: 'flex',
      flexDirection: 'column',
      height: '48px',
      padding: '20px 16px',
      borderBottom: '1px solid rgb(218, 220, 224)',

      '& .primaryListHeader': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontFamily: 'Roboto, Helvetica-neue, sans-serif',
        fontSize: '14px',

        '& span': {
          fontSize: '16px',
          fontWeight: '500',
          marginRight: '5px'
        }
      }
    },

    '& .otherListsContainer': {
      borderBottom: '1px solid rgb(218, 220, 224)',
      '& .otherListsHeader': {
        display: 'flex',
        alignItems: 'center',
        padding: '20px 16px',
        fontFamily: 'Roboto, Helvetica-neue, sans-serif',
        fontSize: '16px',
        fontWeight: '500'
      },

      '& .otherLists': {
        '& .list': {
          display: 'flex',
          flexDirection: 'row',
          height: '72px',
          alignItems: 'center',
          padding: '0px 16px',

          '&.active': {
            color: 'rgb(59, 120, 231)',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',

            '&:hover': {
              backgroundColor: 'rgb(224 224 224)'
            }
          },

          '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
          },

          '& .listText': {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,

            '& .listTitle': {
              fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
              fontSize: '16px',
              fontWeight: '500'
            },

            '& .listMeta': {
              fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
              fontSize: '14px'
            }
          },

          '& .listActions': {
            marginRight: '10px',
            color: 'rgba(0, 0, 0, 0.87)'
          }
        }
      }
    },

    '& .createNewList': {
      padding: '24px',
      display: 'flex',
      justifyContent: 'center',
      color: 'rgb(51, 103, 214)',
      fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
      fontSize: '14px',
      fontWeight: '500',
      borderBottom: '1px solid rgb(218, 220, 224)',

      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#f7f9fd'
      }
    }
  }
})

const SideDrawer = ({ lists, active }) => {
  const [creatingList, setCreatingList] = useState(false)

  const dispatch = useDispatch()
  const isOpen = useSelector((state) => Boolean(path(['menu', 'open'], state)))
  const classes = useStyles()

  const setActiveList = (list) => {
    dispatch({ type: 'list/setActive', payload: list })
  }

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={() => dispatch({ type: 'menu/toggle' })}
    >
      <div className={classes.sideDrawerContainer}>
        <div className="primaryListContainer">
          <div className="primaryListHeader">
            <span>Primary List</span>
            <IconButton>
              <Help />
            </IconButton>
          </div>
        </div>
        <div className="otherListsContainer">
          <div className="otherListsHeader">
            <span>Other lists</span>
          </div>
          <div className="otherLists">
            {lists.map((list) => {
              return (
                <div
                  key={list.id}
                  className={`list ${list.id === active ? 'active' : ''}`}
                  onClick={() => setActiveList(list)}
                >
                  <div className="listText">
                    <span className="listTitle">{list.name}</span>
                    <span className="listMeta">{list.items.length} items</span>
                  </div>
                  <div className="listActions">
                    <SideDrawerListActions list={list} />
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div className="createNewList" onClick={() => setCreatingList(true)}>
          <span>CREATE NEW LIST</span>
        </div>
        <Dialog open={creatingList} onClose={() => setCreatingList(false)}>
          <CreateListForm />
        </Dialog>
      </div>
    </Drawer>
  )
}

export default SideDrawer
