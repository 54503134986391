import faker from 'faker'

// data mocking
export const generateLists = () => {
  let lists = []
  const numLists = faker.random.number(4) + 1
  console.log(`number of lists ${numLists}`)
  for (let i = 0; i < numLists; i++) {
    lists.push(generateList())
  }
  return lists
}

export const generateList = () => {
  const list = {
    id: faker.random.uuid(),
    name: faker.name.jobTitle(),
    items: [],
    created: faker.date.recent().valueOf()
  }

  const numItems = faker.random.number(6) + 1
  for (let i = 0; i < numItems; i++) {
    list.items.push(generateItem())
  }
  return list
}

const generateItem = () => {
  return {
    id: faker.random.uuid(),
    value: generateWords(),
    qty: faker.random.number(4) + 1,
    created: faker.date.recent().valueOf(),
    completed: mockCompleted()
  }
}

export const generateNewItem = (value) => {
  return {
    id: faker.random.uuid(),
    value,
    qty: faker.random.number(4) + 1,
    created: Date.now(),
    completed: null
  }
}

export const generateNewList = (name) => {
  return {
    id: faker.random.uuid(),
    name,
    items: [],
    createdAt: Date.now()
  }
}

const generateWords = () => {
  const numWords = faker.random.number(3) + 1
  return faker.random.words(numWords)
}

const mockCompleted = () => {
  const toComplete = Math.random() > 0.5
  return toComplete ? Date.now().valueOf() : null
}
