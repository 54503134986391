import { IconButton } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import { useDispatch } from 'react-redux'

const ToggleSideDrawerButton = () => {
  const dispatch = useDispatch()

  return (
    <IconButton edge="start" onClick={() => dispatch({ type: 'menu/toggle' })}>
      <Menu />
    </IconButton>
  )
}

export default ToggleSideDrawerButton
