import { AppBar, Toolbar } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import ToggleSideDrawerButton from './ToggleSideDrawerButton'
import TopAppBarMenu from './TopAppBarMenu'
import { prop } from 'ramda'
import UserIcon from '../views/UserIcon'

const useStyles = makeStyles((theme) => {
  return createStyles({
    root: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    topDataContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& .title': {
        alignContent: 'center',
        fontFamily: '"Google Sans", sans-serif',
        fontSize: '20px',
        fontWeight: '500',
        marginLeft: '20px'
      }
    },
    userData: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    appBar: {
      backgroundColor: 'rgb(255, 255, 255)',
      color: 'rgba(0,0,0,.87)'
    }
  })
})

const TopAppBar = ({ activeList }) => {
  const classes = useStyles()

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <ToggleSideDrawerButton />
        <div className={classes.topDataContainer}>
          <span className={'title'}>{prop('name', activeList)}</span>
          <div className={classes.userData}>
            <TopAppBarMenu />
            <UserIcon />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default TopAppBar
