import { prop } from 'ramda'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  shortcutItem: {
    border: '1px solid rgb(216, 216, 216)',
    borderRadius: '5px',
    margin: '8px',
    padding: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '30px',

    '&:hover': {
      cursor: 'pointer',
      borderColor: 'rgb(66, 133, 244, 0.12)',
      backgroundColor: 'rgb(232 240 254)',

      '& span': {
        color: 'rgb(51, 103, 214)'
      }
    },

    '& img': {
      marginRight: '8px'
    },

    '& span': {
      fontFamily: 'Roboto, Helvetica-neue, sans-serif',
      fontSize: '14px',
      fontWeight: '500',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  }
})

const ListItemShortcutItem = ({ item }) => {
  const classes = useStyles()

  return (
    <div className={classes.shortcutItem}>
      <img src={prop('img', item)} alt={prop('text', item)} />
      <span>{prop('text', item)}</span>
    </div>
  )
}

export default ListItemShortcutItem
