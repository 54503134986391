import { makeStyles } from '@material-ui/core/styles'
import ShoppingList from '../list/List'

const useStyles = makeStyles({
  mainContent: {
    margin: '89px auto 0px auto',
    width: '600px'
  }
})

const MainContent = ({ activeList }) => {
  const classes = useStyles()

  return (
    <div className={classes.mainContent}>
      <ShoppingList list={activeList} />
    </div>
  )
}

export default MainContent
