import { IconButton, MenuItem, Menu, Dialog } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { useState } from 'react'
import { styled } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import RenameListForm from './RenameListForm'

const ListMenuItem = styled(MenuItem)({
  padding: '0 24px',
  height: '48px',
  fontFamily: '"Google Sans", sans-serif',
  fontSize: '14px',
  fontWeight: '500'
})

const SideDrawerListActions = ({ list }) => {
  const dispatch = useDispatch()
  const [menu, setMenu] = useState(null)
  const [rename, setRename] = useState(false)

  const handleClick = (event) => {
    setMenu(event.currentTarget)
  }
  const handleClose = () => {
    setMenu(null)
  }
  const handleDelete = () => {
    dispatch({ type: 'list/delete', payload: list })
  }

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu anchorEl={menu} open={Boolean(menu)} onClose={handleClose}>
        <ListMenuItem onClick={() => setRename(true)}>Rename</ListMenuItem>
        <ListMenuItem disabled>Make primary</ListMenuItem>
        <ListMenuItem onClick={handleDelete}>Delete</ListMenuItem>
      </Menu>
      <Dialog open={rename} onClose={() => setRename(false)}>
        <RenameListForm list={list} />
      </Dialog>
    </div>
  )
}

export default SideDrawerListActions
