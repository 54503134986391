import { makeStyles } from '@material-ui/core/styles'
import faker from 'faker'
import ListItemShortcutItem from './ListItemShortcutItem'

const useStyles = makeStyles({
  listItemShortcuts: {
    height: '64px',
    backgroundColor: 'rgb(255, 255, 255)',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgb(218, 220, 224)',
    padding: '0px 16px'
  }
})

const shortcutItems = [
  { text: 'Milk', img: faker.image.food(24, 24) },
  { text: 'Eggs', img: faker.image.food(24, 24) },
  { text: 'Bread', img: faker.image.food(24, 24) },
  { text: 'Bananas', img: faker.image.food(24, 24) }
]

const ListItemShortcuts = () => {
  const classes = useStyles()

  return (
    <div className={classes.listItemShortcuts}>
      {shortcutItems.map((item) => (
        <ListItemShortcutItem key={item.text} item={item} />
      ))}
    </div>
  )
}

export default ListItemShortcuts
