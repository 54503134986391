import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField
} from '@material-ui/core'
import { useState } from 'react'
import { assoc, pipe, prop } from 'ramda'
import { textFieldValue } from '../services/util-service'
import { useDispatch } from 'react-redux'

const RenameListForm = ({ list }) => {
  const dispatch = useDispatch()

  const [name, setName] = useState(prop('name', list))

  const handleSubmit = () => {
    dispatch({ type: 'list/update', payload: assoc('name', name, list) })
  }

  return (
    <div>
      <DialogTitle>Rename list</DialogTitle>
      <DialogContent>
        <TextField value={name} onChange={pipe(textFieldValue, setName)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit}>OK</Button>
        <Button>CANCEL</Button>
      </DialogActions>
    </div>
  )
}

export default RenameListForm
