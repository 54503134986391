import { IconButton } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { path, pipe } from 'ramda'
import { useDispatch } from 'react-redux'
import { generateNewItem } from '../services/list-service'

const styles = makeStyles({
  container: {
    height: '56px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'rgb(255, 255, 255)',
    borderBottom: '1px solid rgb(218, 220, 224)',

    '& .addButton': {
      marginLeft: '10px'
    },

    '& input': {
      '&.itemInput': {
        paddingLeft: '10px',
        fontFamily: '"Google Sans", sans-serif',
        color: 'rgb(154, 160, 166)',
        fontSize: '15px',
        fontWeight: '500',
        flex: 1,
        border: '0',

        '&:focus': {
          outlineStyle: 'none',
          outlineWidth: '0px'
        }
      }
    }
  }
})

const textFieldValue = path(['target', 'value'])

const AddItemInput = ({ list }) => {
  const classes = styles()
  const dispatch = useDispatch()

  const [value, setValue] = useState('')

  const handleAdd = () => {
    const newItem = generateNewItem(value)
    dispatch({ type: 'list/item/create', payload: { list, newItem } })
    setValue('')
  }

  return (
    <div className={classes.container}>
      <div className="addButton">
        <IconButton onClick={handleAdd}>
          <Add />
        </IconButton>
      </div>

      <input
        type="text"
        value={value}
        className="itemInput"
        placeholder="Add item"
        onChange={pipe(textFieldValue, setValue)}
      />
    </div>
  )
}

export default AddItemInput
