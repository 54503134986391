import { filter, isNil, not, pipe, prop } from 'ramda'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import CompletedListItems from './CompletedListItems'
import ListItemShortcuts from './ListItemShortcuts'
import OpenListItems from './OpenListItems'
import AddItemInput from './AddItemInput'

const useStyles = makeStyles(() => {
  return createStyles({
    list: {
      color: 'blue',
      display: 'flex',
      flexDirection: 'column'
    }
  })
})

const isUncompleted = pipe(prop('completed'), isNil)
const isCompleted = pipe(isUncompleted, not)
const assembleOpen = pipe(prop('items'), filter(isUncompleted))
const assmebleClosed = pipe(prop('items'), filter(isCompleted))

const List = ({ list }) => {
  const classes = useStyles()

  return (
    <div className={classes.list}>
      <AddItemInput list={list} />
      <ListItemShortcuts list={list} />
      <OpenListItems items={assembleOpen(list)} />
      <CompletedListItems items={assmebleClosed(list)} />
    </div>
  )
}

export default List
