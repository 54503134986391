import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import React, { useState } from 'react'

const items = ['thing 1', 'thing 2', 'thing 3', 'thing 4']

const TopAppBarMenu = () => {
  const [menuEl, setMenuEl] = useState(null)

  const handleClick = (event) => {
    setMenuEl(event.currentTarget)
  }

  const handleClose = () => {
    setMenuEl(null)
  }

  return (
    <div>
      <IconButton onClick={handleClick} edge="end">
        <MoreVert />
      </IconButton>
      <Menu anchorEl={menuEl} open={Boolean(menuEl)} onClose={handleClose}>
        {items.map((item) => (
          <MenuItem key={item} onClick={handleClose}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default TopAppBarMenu
