import ListItem from './ListItem'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  openListItems: {
    borderBottom: '1px solid rgb(218, 220, 224)'
  }
})

const OpenListItems = ({ items }) => {
  console.log('open items', items)
  const classes = useStyles()

  return (
    <div className={classes.openListItems}>
      {items.map((item) => (
        <ListItem key={item.id} item={item} />
      ))}
    </div>
  )
}

export default OpenListItems
