import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField
} from '@material-ui/core'
import { isEmpty, pipe } from 'ramda'
import { useState } from 'react'
import { textFieldValue } from '../services/util-service'
import { makeStyles, styled } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { generateNewList } from '../services/list-service'

const useStyles = makeStyles({
  container: {
    border: '1px solid red',
    width: '400px',
    borderRadius: '4px'
  },

  input: {
    width: '100%'
  }
})

const NewListInput = styled(TextField)({
  width: '100%'
})

const CreateListForm = () => {
  const dispatch = useDispatch()
  const [name, setName] = useState('')
  const classes = useStyles()

  const handleSubmit = () => {
    console.log('submitting', name)
    dispatch({ type: 'list/create', payload: generateNewList(name) })
  }

  return (
    <div className={classes.container}>
      <DialogTitle>Create a new list</DialogTitle>
      <DialogContent>
        <div className={classes.input}>
          <NewListInput
            value={name}
            placeholder="Name your new list"
            onChange={pipe(textFieldValue, setName)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} disabled={isEmpty(name)}>
          OK
        </Button>
      </DialogActions>
    </div>
  )
}

export default CreateListForm
